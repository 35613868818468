/* Modal */
.ReactModalPortal > div {
	opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
	align-items: center;
	display: flex;
	justify-content: center;
	transition: opacity 0.2s ease-in-out;
	z-index: 999;
}

.modal-fondo {
	background-color: rgba(0, 0, 0, 0.4);
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
	position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
	opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
	opacity: 0;
}

.modal {
	background: #f0f0f0;
	border-radius: 5px;
	color: rgb(51, 51, 51);
	display: inline;
	max-height: 750px;
	max-width: 500px;
	outline: none;
	padding: 10px;
}

.modal .modal-container {
	width: 480px;
	min-height: 100px;
	padding: 30px;
	box-sizing: border-box;
	position: relative;

	.MuiSvgIcon-root.close {
		color: $celeste;
		font-size: 30px;
		cursor: pointer;
		position: absolute;
		top: 8px;
		right: 8px;
	}
}

.modal .header {
	display: flex;
	justify-content: center;
	padding-bottom: 10px;
	border-bottom: 1px solid #dbdbe5;
	margin-bottom: 16px;

	span {
		color: $celeste;
		font-size: 24px;
	}

	.MuiSvgIcon-root {
		color: $celeste;
		font-size: 30px;
		margin-right: 8px;
	}
}


.modal .header.search {
	margin-bottom: 0;
}

.modal .footer {
	display: flex;
	justify-content: space-between;
	padding-top: 16px;
	border-top: 1px solid #dbdbe5;
	margin-top: 16px;
	button {
		background-color: $celeste;
		border: 0;
		border-radius: 8px;
		color: white;
		padding: 10px 16px;
		font-size: 16px;
		cursor: pointer;
		width: 48%;
	}
	button.cancel {
		background-color: $button-grey;
	}
}

.modal .img-container {
	box-sizing: border-box;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	max-height: 240px;
	overflow-y: auto;

	.image {
		width: 110px;
		height: 78px;
		background-size: cover;
		background-position: center;
		margin: 8px 10px;
		border: 2px solid $celeste;
		border-radius: 8px;
		position: relative;

		.remove {
			position: absolute;
			color: $celeste;
			background-color: white;
			right: -12px;
			top: -8px;
			border-radius: 50%;
			cursor: pointer;
		}
		.arrowLeft{
			position: absolute;
			color: $celeste;
			background-color: white;
			right: 98px;
			top: 30px;
			border-radius: 50%;
			cursor: pointer;
		}
	}
}

.modal form.related {
	box-sizing: border-box;
	position: relative;

	.MuiSvgIcon-root {
		position: absolute;
		right: 4px;
		top: 36px;
		color: $celeste;
		cursor: pointer;
	}
}

.related-container ul.related-list{
	overflow-y: auto;
	height: 200px;
	list-style: none;
	padding: 0;
}

.related-container ul.related-list li {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin: 0;
	padding: 8px 0;
	cursor: pointer;

	.MuiSvgIcon-root {
		color: $celeste;
		cursor: pointer;
		font-size: 24px;
		padding-right: 16px;
	}
}

.related-container .label {
	margin-top: 80px;
	text-align: center;
}

.modal .add {
	display: flex;
	align-items: center;
	border: 2px solid $celeste;
	border-radius: 20px;
	padding: 3px 10px;
	margin-top: 24px;
	color: $celeste;
	font-size: 18px;
	cursor: pointer;

	.MuiSvgIcon-root {
		color: $celeste;
		margin-right: 8px;
		font-size: 32px;
	}
}

.modal .info {
	padding: 60px 0;
	color: $button-grey;
}

.modal .loader {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(240, 240, 240, 0.7);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 100;

	.MuiSvgIcon-root {
		color: $celeste;
		font-size: 44px;
	}
}

.rotate {
	animation: rotate 0.8s linear infinite;
}
@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}

.modal form {
	width: 100%;
	position: relative;
}

.modal .input-line {
	box-sizing: border-box;
	margin: 30px 0;
	border: 0;
	background-color: transparent;
	border-bottom: 2px solid $celeste;
	width: 100%;
	font-size: 16px;
	padding: 8px;
}

.modal .input-line::placeholder {
	color: $celeste;
	font-size: 16px;
}
