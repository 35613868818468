.swal2-styled.swal2-confirm {
	background-color: $celeste !important;
}

.swal2-styled.swal2-cancel {
	background-color: transparent !important;
	border: 1.5px solid $celeste !important;
	color: $celeste !important;
}

.swal2-title {
	color: $celeste !important;
	font-size: 24px !important;
	margin-bottom: 16px !important;
}

.swal2-popup {
	padding: 30px 40px 40px 40px !important;
	background-color: #f0f0f0 !important;
	box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.3);
	border-radius: 24px !important;
}
