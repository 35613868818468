.inicio__background {
	width: 100%;
	height: 100%;
	background-position-x: 50%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: white;
}

.inicio__background h2 {
	z-index: 10;
	max-width: 750px;
	padding-left: 200px;
	font-size: 60px;
	font-weight: 600;
	margin-bottom: 60px;
	line-height: 1.1;
}
.inicio__background p {
	z-index: 10;
	font-size: 28px;
	padding-left: 200px;
	max-width: 750px;
}
