.login__container {
	padding: 60px;
	color: white;
}

.login__form {
	display: flex;
	width: 400px;
	max-width: 90%;
	height: 600px;
	flex-direction: column;
	padding: 40px;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 30px;
	margin: auto auto;
	margin-top: 50px;
}

.login__logo {
	width: 200px;
	height: auto;
	margin: 0 auto;
	z-index: 10;
}

.login__form input {
	border: 0;
	background-color: white;
	padding: 16px;
	border-radius: 4px;
	margin: 10px 0;
	z-index: 10;
	font-size: 1.2rem;
}

.login__form h2 {
	z-index: 10;
	text-align: center;
	margin-bottom: 40px;
	font-size: 28px;
}

.login__form h2.lighter {
	font-weight: 300;
	margin: 40px auto 5px;
}

.login__form p {
	z-index: 10;
	margin-top: 8px;
}

.login__form button {
	background-color: $button-active-blue;
	border: 0;
	border-radius: 4px;
	padding: 16px;
	color: white;
	font-size: 1.1rem;
	z-index: 10;
	margin: 20px 0;
	cursor: pointer;
}
