.aside__aside {
	width: 100%;
	height: 100%;
	background-color: $primary;
	display: flex;
	flex-direction: column;
	padding: 60px 0 60px 60px;
	color: white;
	box-sizing: border-box;
	position: relative;
}

.aside__logo {
	width: 150px;
	height: 130px;
	margin-bottom: 40px;
	background-size: cover;
}

.aside__aside h2 {
	font-size: 30px;
	font-weight: 300;
}

.aside__aside nav {
	margin-top: 40px;
}

.aside__aside nav a {
	color: white;
	text-decoration: none;
	display: block;
	border-bottom: 1px solid orange;
	display: flex;
	align-items: center;
	padding: 10px 0;
	margin: 4px 0;
}

.aside__aside nav a.active {
	color: orange;
	border-bottom: 3px solid orange;
}

.aside__aside nav a span {
	font-size: 18px;
	font-weight: bold;
	padding-left: 16px;
}

.aside__aside nav a .MuiSvgIcon-root {
	font-size: 28px;
}

.aside__aside nav a .icon {
	height: 22px;
	width: 32px;
	background-size: cover;
}

// .aside__aside nav a img {
// 	height: 22px;
// }

.aside__aside nav a .svg-sider {
	padding-left: 14px;
}

.aside__usuario-activo {
	background-color: $celeste;
	color: white;
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px 0;
	p {
		font-weight: 600;
	}
}
