* {
	font-family: Helvetica, Arial, sans-serif;
	margin: 0px;
}

html,
body {
	height: 100vh;
	width: 100vw;
}

main {
	width: 100%;
}

.background {
	height: 100vh;
	width: 100vw;
	background-size: cover;
	position: relative;
}

.background::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(36, 36, 36, 0.5);
}

section {
	padding: 60px;
	max-width: 1200px;

	h2.title {
		font-size: 40px;
		font-weight: bold;
		color: $primary;
	}
	p.bajada {
		color: #150c67;
		font-size: 20px;
		margin: 20px 0 40px;
	}

	.button-volver {
		border: 2px solid $celeste;
		background-color: white;
		border-radius: 10px;
		display: flex;
		align-items: center;
		padding: 8px 16px;
		color: $celeste;
		font-size: 14px;
		font-weight: 500;
		text-decoration: none;
	}
}

section form.input-form {
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
}

section form.input-form .input {
	width: 100%;
	display: flex;
	position: relative;
	width: 100%;
	align-items: center;
	padding: 12px 16px;
	border: 1px solid $celeste;
	border-radius: 8px;
	margin-bottom: 10px;
	justify-content: space-between;
	box-sizing: border-box;
	color: $button-grey;
	font-weight: 600;
	box-sizing: border-box;
}

section form.input-form .visibilidad {
	position: absolute;
	right: 20px;
	cursor: pointer;

	.MuiSvgIcon-root {
		// position: absolute;
		// right: 14px;
		// font-size: 20px;
		color: $celeste;
	}
}

section form.input-form .input input {
	width: 70%;
	padding: 6px;
	border: 0;
	border-bottom: 2px solid $celeste;
	font-size: 16px;
}

section form.input-form .input input::placeholder {
	color: $celeste;
	font-size: 16px;
}

section form.input-form .input.simple,
section form.input-form .input.triple {
	padding: 0;
	border: 0;
	margin-bottom: 40px;
	flex-direction: column;
	align-items: flex-start;

	input {
		width: 100%;
		box-sizing: border-box;
	}
}

section form.input-form .input.triple.center {
	align-items: center;
}

section form.input-form input.date {
	position: relative;
	color: $button-grey;
}

section form.input-form input.date::-webkit-calendar-picker-indicator {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	color: transparent;
	background: 0 0;
	margin: 0;
	opacity: 0;
	pointer-events: auto;
}

section form.input-form .input.triple {
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;

	input {
		width: calc(100% - 420px);
	}

	.custom-input {
		background-color: white;
		position: relative;
		box-sizing: border-box;

		.bloque {
			border: 1px solid #ccc;
			display: flex;
			align-items: center;
			margin-left: 10px;

			input {
				width: 100%;
				border: 0;
				padding: 10px;
				padding-left: 4px;
			}

			input::placeholder {
				color: $button-grey;
				font-size: 14px;
			}

			.icon {
				position: relative;
				color: $celeste;
				right: 0;
				padding-left: 10px;
			}

		}
		.bloque.vistas {
			width: 60px;
		}
		.bloque.vistas input {
			text-align: center;
		}

		.dolar {
			padding-left: 10px;
		}
		.dolar::before {
			content: "U";
			color: #2799fa;
			font-size: 16px;
			position: absolute;
			left: 22px;
			bottom: 8px;
		}
	}
	p.label {
		text-transform: uppercase;
		font-weight: 400;
		font-size: 10px;
		margin: 0 0 8px 10px;
	}

	.loader-file {
		position: absolute;
		background-color: rgba(255, 255, 255, 0.6);
		width: 100%;
		bottom: 0;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 100;

		.MuiSvgIcon-root {
			color: $celeste;
		}
	}

	.radio-set {
		color: initial;
		font-weight: 400;
		width: calc(100% - 360px);
		border-bottom: 1px solid #e3e4e5;
		padding-bottom: 10px;

		span {
			cursor: pointer;
		}

		.MuiSvgIcon-root {
			position: relative;
			left: 0;
			bottom: -4px;
			margin: 0 8px 0 16px;
			color: $celeste;
		}
	}

	span.check-label {
		color: #757575;
		width: 500px;
		font-weight: initial;
	}

	.check-set {
		color: initial;
		font-weight: 400;
		width: calc(100% - 360px);
		border-bottom: 1px solid #e3e4e5;
		padding-bottom: 10px;

		span {
			cursor: pointer;
		}

		.MuiSvgIcon-root {
			position: relative;
			left: 0;
			bottom: -6px;
			margin: 0 16px 0 8px;
			color: $celeste;
		}
	}
}

button.bloque {
	background-color: white;
	padding: 8px;
	cursor: pointer;
	color: $button-grey;
	width: 100%;
	box-sizing: border-box;

	.icon {
		right: 8px !important;
	}
}

section form.input-form .ck-editor__editable_inline {
	min-height: 170px;
}

section form.input-form .editor {
	margin-bottom: 45px;
}

section form.input-form .ck.ck-editor {
	border-top: 2px solid $celeste;
}

section form.input-form span.input-label {
	text-transform: uppercase;
	font-size: 12px;
	color: initial;
	margin-bottom: 15px;
	font-weight: 600;
}
section form.input-form .form-add {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	border: 0;
	background-color: $button-active-blue;
	padding: 10px;
	border-radius: 10px;
	font-size: 16px;
	font-weight: 600;
	cursor: pointer;

	.MuiSvgIcon-root {
		margin-right: 10px;
		font-size: 28px;
	}
}

section .add-row {
	display: flex;
	align-items: center;
	color: $celeste;
	border: 0;
	background-color: white;
	font-size: 16px;
	padding: 10px 0 0 0;
	cursor: pointer;

	.MuiSvgIcon-root {
		margin-right: 10px;
		font-size: 26px;
	}
}

section form textarea {
	width: 100%;
	height: 150px;
	margin-bottom: 40px;
	box-sizing: border-box;
	font-size: 15px;
	padding: 10px;
	border: 1px solid #ccc;
	resize: none;
}

.row {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.active-button,
.info-button {
	color: white;
	border-radius: 8px;
	padding: 12px 16px;
	display: flex;
	align-items: center;
	font-size: 14px;
	font-weight: 600;
	margin-right: 30px;
}

.info-trans {
	display: flex;
	align-items: center;
	padding: 10px;
	border-radius: 10px;
	margin-right: 16px;
	text-transform: uppercase;
	font-weight: 600;
	background-color: $light-grey;

	.flag {
		margin-right: 10px;
	}
}

.info-lang {
	display: flex;
	align-items: center;
	padding-left: 12px;
	font-size: 16px;
	font-weight: 400;

	.flag {
		margin-right: 10px;
	}
}

.active-button {
	background-color: #2379f4;
	border: 0;
	padding: 8px 16px;
	text-decoration: none;
	margin-right: 0;
}

.active-button .MuiSvgIcon-root {
	font-size: 30px;
	margin-right: 10px;
}

.info-button {
	background-color: $button-grey;
}

.info-button span {
	font-size: 20px;
	margin-right: 10px;
}

/****/

.simple-table {
	display: grid;
	margin-top: 30px;
	box-sizing: border-box;
	grid-template-columns: 1fr 1fr;
}

.simple-table .simple-row {
	border: 1px solid lighten($color: $button-grey, $amount: 30);
	border-top: 0;
	padding: 10px 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.simple-table .simple-row .MuiSvgIcon-root {
	color: $celeste;
	font-size: 20px;
	cursor: pointer;
}

.simple-table .simple-row.header {
	background-color: $light-grey;
	border-top: 3px solid $celeste;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 13px;
	padding: 16px;
}

.label-ficha {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	border: 0;
	background-color: transparent;
	position: relative;

	.ficha-upload {
		background-color: #2799fa;
		color: white;
		padding: 8px;
		border-radius: 4px 4px 0 0;
		display: flex;
		align-items: center;
		cursor: pointer;

		.MuiSvgIcon-root {
			margin-right: 6px;
		}
	}

	.loader-pdf {
		position: absolute;
		background-color: rgba(255, 255, 255, 0.6);
		width: 252px;
		bottom: 0;
		right: 0;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 100;

		.MuiSvgIcon-root {
			color: $celeste;
		}
	}
}

/****/

.table {
	display: grid;
	margin-top: 60px;
	box-sizing: border-box;
}

/**/
.table.cat {
	margin: 0 0 20px 0;
	grid-template-columns: 1fr 1fr;
}

.label-cat {
	margin-right: 10px;
	color: #757575;
}

ul.lista-desplegable {
	position: absolute;
	background-color: white;
	list-style: none;
	top: 50px;
	z-index: 10;
	width: 100%;
	padding: 10px 0;
	border: 1px solid #dfdfdf;
	box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	display: none;
}

ul.lista-desplegable.visible {
	display: block;
}

ul.lista-desplegable li {
	padding: 10px 20px;
}

ul.lista-desplegable li:hover {
	background-color: #edf4fe;
}

/* desplegable en user */

.input-desplegable {
	position: relative;
	padding: 8px 16px 16px;
	border: 1px solid #2799fa;
	border-radius: 8px;
	cursor: pointer;
}

.input-desplegable span.desplegable {
	color: #94989b;
	font-weight: 600;
}

.input-desplegable .MuiSvgIcon-root.chevron {
	position: relative;
	top: 7px;
}

.input-desplegable ul.lista-desplegable {
	width: 400px;
	top: 45px;
}

.input-desplegable .rol-selected {
	padding-left: 64px;
}

/**/
a.edit {
	color: $celeste;
	text-decoration: none;
	display: flex;
	align-items: center;
	padding: 7px;

	.MuiSvgIcon-root {
		padding-right: 7px;
	}
}

.MuiSvgIcon-root.chevron {
	color: $celeste;
	position: relative;
	left: 6px;
	top: 1px;
}

.table-row.end {
	justify-content: flex-end;
}

.table.usuarios {
	grid-template-columns: 1fr 1fr 240px;
}

.table.productos,
.table.lang {
	grid-template-columns: 1fr 1fr 1fr 240px;
}

.table.faqs,
.table.novedades,
.table.slider,
.table.categorias {
	grid-template-columns: 1fr 240px;
}

.table .table-header {
	background-color: #f5f6fa;
	padding: 16px 10px;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
}

.table .table-header.actions {
	text-align: center;
}

.table.productos .row.table-header-actions,
.table.lang .row.table-header-actions {
	grid-column-start: 4;
}

.table.usuarios .row.table-header-actions {
	grid-column-start: 3;
}

.table.slider .row.table-header-actions,
.table.novedades .row.table-header-actions,
.table.faqs .row.table-header-actions,
.table.categorias .row.table-header-actions {
	grid-column-start: 2;
}

.complete {
	grid-column-start: 1;
	grid-column-end: 3;
}

.lang.complete {
	grid-column-end: 5;
}

.table .table-row {
	display: flex;
	border-bottom: 1px solid lighten($color: $button-grey, $amount: 30);
	padding: 10px;
	align-items: center;
	position: relative;
	min-height: 40px;
}

.table .table-row span.desplegable {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.table .table-header-actions span {
	width: 33%;
	text-transform: uppercase;
	font-size: 12px;
	color: $button-grey;
	padding: 10px 10px 20px;
	font-weight: 600;
	text-align: center;
}

.table .avatar {
	background-color: $celeste;
	color: white;
	border-radius: 50%;
	padding: 10px;
	font-size: 16px;
	font-weight: 600;
	margin-right: 16px;
	min-width: 21px;
	text-align: center;
}

.related {
	color: initial;
	font-weight: initial;
	display: flex;
	align-items: center;
	cursor: pointer;

	.MuiSvgIcon-root {
		color: $celeste;
		margin-right: 8px;
	}
}

section .flag {
	width: 24px;
	height: 24px;
	margin-right: 16px;
	border-radius: 50%;
	background-size: cover;
}

.icon-set .icon {
	width: 33%;
	border-right: 1px solid lighten($color: $button-grey, $amount: 20);
	display: flex;
	justify-content: center;
	cursor: pointer;
}

.icon-set {
	box-sizing: border-box;
	padding: 0;
	display: contents;
}

.icon-set .icon .MuiSvgIcon-root {
	color: $primary;
}

.table .icon-set .icon.green .MuiSvgIcon-root {
	color: $green;
}

/***/

.filter {
	position: relative;
    margin-top: 30px;
	width: 320px;
	box-sizing: border-box;
}

.filter input {
	padding: 8px 20px 8px 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	width: 100%;
	box-sizing: border-box;
	right: 2px;
	top: 4px;
}

.filter .MuiSvgIcon-root {
	color: $celeste;
	cursor: pointer;
	position: absolute;
	right: 2px;
	top: 4px;
}


/****/

.mt-40 {
	margin-top: 40px;
}
.mtb-0 {
	margin: 0 !important;
}
.mb-40 {
	margin-bottom: 40px;
}
