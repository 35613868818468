//colors

$primary: #150c67;
$dark-grey: #363636;
$light-grey: #f5f6fa;

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #2379f4;

$button-grey: #94989b;

$celeste: #2799fa;
$green: #32ad5d;
