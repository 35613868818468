.tab-list {
	background-color: #f5f6fa;
	text-align: center;
	width: 100%;
	cursor: pointer;
	color: #2379f4;
	font-size: 16px;
	font-weight: 600;
	box-sizing: border-box;
	border: 0;
	padding: 0;
	display: flex;
	justify-content: space-around;
	border-radius: 0 30px 0 0;
	margin-bottom: 40px;
}

.tab-list::after {
	content: "";
	position: absolute;
	width: 100%;
	background-color: transparent;
	height: 1px;
	bottom: -1px;
	box-shadow: 1px -4px 6px 0px rgba(0, 0, 0, 0.3);
}

.tab-list-item {
	list-style: none;
	cursor: pointer;
	background-color: transparent;
	padding: 16px;
	text-align: center;
	width: inherit;
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	box-sizing: border-box;
	border: 0;
}

.tab-list-active {
	color: white;
	background-color: #2379f4;
	border-radius: 0 30px 0 0;
	z-index: 10;
	box-shadow: 1px -1px 6px 0px rgba(0, 0, 0, 0.4);
	width: inherit;
}
