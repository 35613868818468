.layout__layout {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: row;
	box-sizing: border-box;
}

.layout__aside {
	width: 380px;
	height: 100vh;
	box-sizing: border-box;
}

.layout__content {
	width: calc(100% - 380px);
	height: 100vh;
	box-sizing: border-box;
}
